import { Modal } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const connectOur = () => {
    setIsOpen(true);
  };
  return (
    <>
      <div className="md:w-[1200px] mx-auto mmd:mx-16">
        <div className="flex gap-24">
          <div className="bg-[#19FB9B] w-[50px] h-1"></div>
          <div className="flex-1 bg-[#22262A]  h-1"></div>
        </div>
        <div className="flex md:justify-between items-center mmd:flex-col text-[#C4CBD4] md:py-26 mmd:py-24">
          <p className="text-16">©  2024   Zen Ancestral Hall，Inc.</p>
          <div className="flex md:gap-48 mmd:justify-between mmd:mt-12 mmd:w-full text-14">
            <Link to="/" className="w-76 text-center py-12">
              首页
            </Link>
            <Link to="/proxy" className="w-76 text-center py-12">
              代理
            </Link>
            <div
              className="w-76 text-center py-12 cursor-pointer"
              onClick={connectOur}
            >
              联系我们
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isOpen}
        className="md:!w-[530px] mmd:!w-[90vw] modal-custom border border-solid border-[#22262A] rounded-[24px]"
        centered
        footer={null}
        onCancel={() => setIsOpen(false)}
      >
        <p className="text-28 mmd:text-24 text-white mb-24 mmd:mb-16">
          联系我们
        </p>
        <p className="text-16 text-[#818C99]">加入禅宗祖庭，开启加密生活与禅修智慧的全新旅程！</p>

        <div className="flex flex-col gap-48 mt-48 mmd:gap-24 mmd:mt-24">
          <input
            type="email"
            placeholder="电子邮件"
            className="w-full bg-[#32383E] h-52 px-24 rounded-[6px] placeholder:text-[#818C99] text-white outline-none"
          />
          <div>
            <div className="cursor-pointer inline-block py-10 rounded-[100px] px-33 text-[#818C99] bg-[#32383E]">
              提交
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Footer;
