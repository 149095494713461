import { Drawer } from 'antd'
import { useState } from 'react'
import './index.less'
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

const Headers = () => {
  const [opened, setopened] = useState(false)
  // const [change, setchange] = useState(0)
  // const menu = [{
  //   label: "華語",
  //   key: 'cn',
  //   icon: <img src="https://cam3lot.io/images/len/zh.png" className='w-[1.2vw] rounded-full' alt={'cn'} />
  // }, {
  //   label: "EngLish",
  //   key: 'enUS',
  //   icon: <img src="https://cam3lot.io/images/len/en.png" className='w-[1.2vw] rounded-full' alt={'cn'} />
  // }]
  const location = useLocation()
  const [activeUrl, setactiveUrl] = useState(location.pathname)

  const urlList = [{
    label: '首页',
    internal: true,
    url: '/',
    activeUrl: ['/'],
    isButton: false
  },{
    label: '代理',
    internal: true,
    url: '/proxy',
    activeUrl: ['/proxy'],
    isButton: false
  }]

  /**
   * 
   * , {
    label: '立即预订',
    internal: true,
    url: '/pre-buy',
    activeUrl: ['/pre-buy'],
    isButton: true
  }
   */

  // const currentLang = useMemo(() => {
  //   return menu.find(val => val.key === i18next.language)
  //   // eslint-disable-next-line
  // }, [i18next.language])

  const navigator = useNavigate()

  return (
    <>
      <div className='sticky top-0 left-0 right-0 z-[10001] bg-[#07080d]'>
        <div className='md:w-[1200px] mx-auto flex justify-between items-center box-border mmd:px-16 mmd:py-23 md:py-46 md:px-10'>
          <div className='flex items-center cursor-pointer' onClick={() => {
            navigator('/')
          }}>
            <img src="./logo.png" alt="" className='w-[195px] mmd:w-190' />
          </div>
          <div className='flex items-center gap-10 connect-button'>
            <div className='flex mmd:hidden gap-50 pc-urlList text-white'>
              {urlList.map((val, index) => {
                return <div key={index}  className={`flex url-item cursor-pointer px-24 py-12 text-14 font-bold ${val.activeUrl?.includes(activeUrl) ? 'text-white' : 'text-[#C4CBD4]'} ${val.isButton ? 'bg-white rounded-[30px] !text-black' : ''}`}  onClick={() => {
                  if(val.url && !val.internal) {
                    window.open(val.url, 'target=_blank')
                    return
                  }
                  navigator(val.url || '/')
                  setactiveUrl(val.url)
                }}>
                  <span>{val.label}</span>
                </div>
              })}
            </div>
            <img src="./images/list.png" alt="" className='w-48 md:hidden cursor-pointer' onClick={() => setopened(!opened)} />
          </div>
        </div>
      </div>
      <Drawer
        open={opened}
        height={'auto'}
        onClose={() => setopened(false)}
        placement={'top'}
        closable={false}
        styles={{
          body: {
            padding: 0
          }
        }}
      >
        <div className='pt-94 bg-[#07080d]'>
          {urlList.map((val, index) => {
            return <div key={index}  className={`p-34 text-[#C4CBD4] border-t border-[#22262A] border-solid text-22 items-center justify-between flex url-item cursor-pointer ${val.activeUrl?.includes(activeUrl) ? 'active' : ''}`}  onClick={() => {
              if(val.url && !val.internal) {
                window.open(val.url, 'target=_blank')
                return
              }
              navigator(val.url || '/')
              setactiveUrl(val.url)
              setopened(false)
            }}>
              <span className={val.isButton ? "text-[#07080D] bg-white w-full py-16 rounded-[50px] text-center text-18" : ""}>
              {val.label}
              </span>
              {!val.isButton && <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' fill="none" viewBox="0 0 14 14"><path strokeLinecap="square" strokeWidth="1.75" stroke="#C4CBD4" d="M5.25 2.625 9.625 7 5.25 11.375"/></svg>}
            </div>
          })}
        </div>
      </Drawer>
    </>
  )
}

export default Headers