import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import Headers from "./components/Header";
import Footer from "./components/Footer";
import { useState } from "react";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'

function App() {
  const [hiddenFooter, sethiddenFooter] = useState(false);
  const [hiddenHeader, sethiddenHeader] = useState(false);

  const projectId = "b33fa9454e2e02ce8560a7b39c4f95f4";
  const metadata = {
    name: 'My Website',
    description: 'My Website description',
    url: 'https://mywebsite.com', // origin must match your domain & subdomain
    icons: ['https://avatars.mywebsite.com/']
  }
  
  
  // 4. Create Ethers config
  const ethersConfig = defaultConfig({
    /*Required*/
    metadata,
    auth: {
      email: false,
      socials: [],
    },
    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    defaultChainId: 56, // used for the Coinbase SDK
  })
  
  // 5. Create a Web3Modal instance
  createWeb3Modal({
    ethersConfig,
    enableSwaps: false,
    enableOnramp: false,
    allWallets: 'HIDE',
    themeMode: 'dark',
    chains: [{
      chainId: 56,
      name: 'BSC',
      currency: 'BNB',
      explorerUrl: 'https://bscscan.io',
      rpcUrl: 'https://binance.llamarpc.com'
    }],
    projectId,
    enableAnalytics: false, // Optional - defaults to your Cloud configuration
  })

  return (
    <div className="App">
      <BrowserRouter>
        {!hiddenHeader && <Headers />}
        <Routes
          sethiddenHeader={sethiddenHeader}
          sethiddenFooter={sethiddenFooter}
        />
        {!hiddenFooter && <Footer />}
      </BrowserRouter>
    </div>
  );
}

export default App;
