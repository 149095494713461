import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { Home, Proxy, PreBuy, Pay } from '../pages'
type CutomFallBackT =
  | boolean
  | React.ReactChild
  | React.ReactFragment
  | React.ReactPortal
  | null
type ChildT = React.LazyExoticComponent<() => JSX.Element> | React.FC
export interface routeProps {
  getHealthcheck?: ()=> Promise<void>
}
// 加载异步组件的loading
const SuspenseWrapper = (Child: ChildT, cutomFallBack?: CutomFallBackT): any => {
  return (
    <React.Suspense fallback={cutomFallBack || null}>
      <Child />
    </React.Suspense>
  )
}
const Routes = (props: {sethiddenHeader: Function, sethiddenFooter: Function}) => {
  const RouterList = useRoutes([
    {
      path: '/',
      element: SuspenseWrapper(
        () => <Home />
      ),
    },
    {
      path: '/proxy',
      element: SuspenseWrapper(
        () => <Proxy />
      ),
    },
    {
      path: '/pre-buy',
      element: SuspenseWrapper(
        () => <PreBuy {...props} />
      ),
    },
    {
      path: '/pay',
      element: SuspenseWrapper(
        () => <Pay {...props} />
      ),
    },
    { path: '*', element: <Navigate to="/" replace /> }
  ])
  return RouterList
}
export default Routes